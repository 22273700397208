
/*
* classToggler()
* A simple data attributes based class toggler plugin.
* Class toggling is one of the most useful things in a frontend world, it can help you create simple dynamic UI elements with ease.
*
* Basic usage instructions:
*
* Add classToggler instance to your page:
* 1. Include classToggler.js file in the end of your document;
* 2. Initialize the plugin with: classToggler().init();
* 3. Follow the instructions bellow;
*
* On a toggle element specify the [data-toggle] attribute that point to a target element to toggle.
* Specify the class to be toggled on the target element with a [data-toggle-class] attribute.
* If you need to toggle a class on the source (e.g. "--active" modifier) use [data-toggle-self] attribute.
* By default plugins responds to a "click" event and toggles specified class on a specified target element.
*
* That's basically it.
*/

/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.classToggler = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {

  'use strict';

  var classToggler = {};

  // default configuration options
  // @todo make options configurable (events at least)
  // defines target element class
  var mainAttr = "data-toggle";
  // defines class to be toggled on a target element
  var mainTargetAttr = "data-toggle-class";
  // defines a class to toggle on source element
  var mainToggleSelfAttr = "data-toggle-self";
  // defines a time for the class to be re-toggled, by default there is no re-toggle
  var mainToggleTimeoutAttr = "data-toggle-timeout";
  // defines event type to perform toggling omn
  var mainEventType = "click";

  // Private methods

  /**
   * Attaches event handlers to all elements with another target found
   * @private
   */
  var attachTargetEventHandlers = function (element) {
    var targetTimeout;

    element.addEventListener(mainEventType, function (event) {
      var targetElementClassName = event.currentTarget.getAttribute(mainAttr);
      var targetClassNameToToggle = event.currentTarget.getAttribute(mainTargetAttr);
      var targetElement = document.getElementsByClassName(targetElementClassName);
      var toggleTimeout = event.currentTarget.getAttribute(mainToggleTimeoutAttr);

      // perform toggling only when element is found, otherwise throw a warning
      if (targetElement.length > 0) {
        targetElement[0].classList.toggle(targetClassNameToToggle);
        event.stopPropagation();

        if (toggleTimeout) {
          clearTimeout(targetTimeout);
          targetTimeout = window.setTimeout(function () {
            targetElement[0].classList.toggle(targetClassNameToToggle);
          }, toggleTimeout);
        }
      } else {
        /* eslint-disable no-console */
        console.warn('classToggler error: Cannot find element with class ' + targetElementClassName);
      }
    });
  };

  /**
   * Attaches event handlers to all elements targeting themselves
   * @private
   */
  var attachSelfEventHandlers = function (element) {
    var selfTimeout;

    element.addEventListener(mainEventType, function (event) {
      var self = event.currentTarget;
      var selfClassNameToToggle = self.getAttribute(mainToggleSelfAttr);
      var selfToggleTimeout = self.getAttribute(mainToggleTimeoutAttr);

      self.classList.toggle(selfClassNameToToggle);

      if (selfToggleTimeout) {
        clearTimeout(selfTimeout);
        selfTimeout = window.setTimeout(function () {
          self.classList.toggle(selfClassNameToToggle);
        }, selfToggleTimeout);
      }
    });
  };

  // Public methods

  /**
   * Initialize the plugin
   */
  classToggler.init = function() {
    var activeTargetToggleElements = document.querySelectorAll('['+ mainAttr +']');
    var activeSelfToggleElements = document.querySelectorAll('['+ mainToggleSelfAttr +']');

    // since IE doesn't support forEach for NodeList, going old fashioned way
    for (var i = 0; i < activeTargetToggleElements.length; i++) {
      attachTargetEventHandlers(activeTargetToggleElements[i]);
    }

    for (var j = 0; j < activeSelfToggleElements.length; j++) {
      attachSelfEventHandlers(activeSelfToggleElements[j]);
    }
  };

  return classToggler;
});
